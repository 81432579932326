<template>
<v-main id="master_app">
  <v-app id="app">
    <project-report-details :project_report="project_report" :settings="settings" />
  </v-app>
</v-main>
</template>

<script>
import {mapState,mapActions} from "vuex"
export default {
  name: "App",
  components: {
    ProjectReportDetails: () => import("@/components/projectreportdetails"),
  },
  async created(){
    this.getSettings();
    this.getProjectReport();
  },
  computed:{
    ...mapState("project", ["project_report"]),
    ...mapState("homepage", ["settings"]),
  },
  methods: {
    ...mapActions("homepage", ["getSettings"]),
    ...mapActions("project", ["getProjectReport"]),
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
#master_app #app{
    font-family: 'Open Sans', sans-serif;
}
</style>